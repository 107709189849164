import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Inspection, Lender } from "../models";
// import { DataStore, SubscriptionMessage } from "@aws-amplify/datastore";
import { DataStore } from '@aws-amplify/datastore';
import { ZenObservable } from "zen-observable-ts";
import DataTable from "../components/DataTable";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

let columns: any[] = [
  {
    key: "id",
    header: "Loan ID",
    renderFn: (rowData: any) => {
      return (
        <Link component={RouterLink} to={`/inspection/${rowData.id}`}>
          {rowData.loanId ? rowData.loanId : 'No ID'}
        </Link>
      );
    },
  },
  {
    key: "name",
    header: "Name",
    renderFn: (rowData: any) => {
      return (
        <Link component={RouterLink} to={`/inspection/${rowData.id}`}>
          {rowData.name ? rowData.name : 'Untitled'}
        </Link>
      );
    },
  },
];
export default function Inspections(props: any) {
  const { archived } = props

  const inspectionsSubscriptionRef = useRef<
    ZenObservable.Subscription | undefined
  >();
  const [inspections, setInspections] = useState<any>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [lenders, setLenders] = useState<any>([]);
  const [lenderId, setLenderId] = useState("");

  let history = useHistory();

  // datastore inspections subscription
  useEffect(() => {
    const subscription = DataStore.observe(Inspection).subscribe((msg) => {
      fetchInspections();
    });
    inspectionsSubscriptionRef!.current = subscription;
    return () => {
      inspectionsSubscriptionRef!.current!.unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchInspections();
  }, [searchString, lenderId, archived]);

  useEffect(() => {
    fetchLenders().then((lendersData) => {
      setLenders(lendersData);
    });
  }, []);

  async function fetchInspections() {
    try {
      let queryFn: any = (c: any) => c.archived("ne", true);

      if (archived) {
        queryFn = (c: any) => c.archived("eq", true);
      }

      if (searchString) {
        queryFn = (c: any) => {
          let subject = c
          if (archived) {
            subject = subject.archived("eq", true)
          }
          return subject.or((c: any) =>
            c.searchField("contains", searchString).loanId("eq", searchString)
          );
        }
      }

      if (lenderId) {
        const inspectionsData = ((await DataStore.query(
          Inspection,
          queryFn
        )) as any).filter((c: any) => c.lender.id == lenderId);
        setInspections(inspectionsData.sort((a: any, b: any) => {
          if (a.loanId > b.loanId) {
            return 1
          } else if (b.loanId > a.loanId) {
            return -1;
          } else {
            return 0;
          }
        }));
      } else {
        const inspectionsData: any = await DataStore.query(Inspection, queryFn)
        setInspections(inspectionsData.sort((a: any, b: any) => {
          if (a.loanId > b.loanId) {
            return 1
          } else if (b.loanId > a.loanId) {
            return -1;
          } else {
            return 0;
          }
        }));
      }
    } catch (err) {
      throw err
      console.log("error fetching inspections", err);
    }
  }

  function handleChangeSearch(input: string) {
    setSearchString(input);
  }
  async function fetchLenders() {
    try {
      const lendersData = await DataStore.query(Lender);
      return lendersData;
    } catch (err) {
      console.log("error fetching Lenders", err);
    }
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <TextField
            // error={state.errors['name']}
            variant="outlined"
            margin="normal"
            type="search"
            placeholder="Search Loan ID, Name, Address"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChangeSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="demo-simple-select-outlined-label">
              Lender
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // value={age}
              onChange={(e) => setLenderId(e.target.value as string)}
              label="Lender"
              required
              value={lenderId}
            >
              <MenuItem value="">None</MenuItem>
              {lenders.map((lender: any) => {
                return (
                  <MenuItem key={lender.id} value={lender.id}>
                    {lender.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <DataTable columns={columns} data={inspections} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/inspection/add");
            }}
          >
            Add Inspection
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
