import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Amplify,Auth} from "aws-amplify";
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);
/*
Amplify.configure({
  Auth: {
      identityPoolId: 'us-east-1:de558098-5600-43df-ba3b-944d84f0001f',
      region: 'us-east-1',
      userPoolId: 'us-east-1_wrkJkL5Rw',
      userPoolWebClientId: '71e5llogo4o12omdevebp9lsom'
  }
});
*/
Amplify.Logger.LOG_LEVEL = 'DEBUG';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
