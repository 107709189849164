import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InspectionIcon from '@material-ui/icons/Assessment';
import ListItemLink from './ListItemLink'

export const mainListItems = (
  <div>
    <ListItemLink to="/lender" primary="Lenders" icon={<AccountBalanceIcon />} />
    <ListItemLink to="/inspection" primary="Inspections" icon={<InspectionIcon />} />
    <ListItemLink to="/archivedinspections" primary="Archived Inspections" icon={<InspectionIcon />} />
  </div>
);