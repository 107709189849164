import React from "react";
import "./App.css";
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import {
  BrowserRouter as Router,
  Switch as RouterSwitch,
  Route,
  Redirect
} from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Layout from "./components/Layout";
import LenderPage from "./pages/Lender";
import AddLender from "./components/AddLender";
import CreateInspection from "./components/CreateInspection"
import Inspections from "./components/Inspections"
import { DataStore } from '@aws-amplify/datastore';
DataStore.start()

function App() {
  return (
    <Router>
      <Layout>
        <AmplifySignOut />
        <Box mt={6}>
          <RouterSwitch>
            <Route exact path="/">
              <Redirect to="/inspection" />
            </Route>
            <Route exact path="/lender">
              <LenderPage />
            </Route>
            <Route exact path={["/lender/add", "/lender/:id"]}>
              <AddLender />
            </Route>
            <Route exact path="/inspection">
              <Inspections />
            </Route>
            <Route exact path="/archivedinspections">
              <Inspections archived={true} />
            </Route>
            <Route exact path={["/inspection/add", "/inspection/:id"]}>
              <CreateInspection />
            </Route>
          </RouterSwitch>
        </Box>
      </Layout>
    </Router>
  )
}

export default withAuthenticator(App);
