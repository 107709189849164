import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { createLender, updateLender } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api";
import { CreateLenderMutation, UpdateLenderMutation } from '../API'
import { DataStore } from '@aws-amplify/datastore';
import { Lender } from '../models'
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  datePickersWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imgUploader: {
    '& .uploadPictureContainer': {
      width: '100%'
    }
  }
}));

interface FormState {
  [key: string]: string
  name: string,
  email: string
}

const initialState: FormState = {
  name: '',
  email: ''
}

export default function AddLender() {
  const classes = useStyles()
  const [formState, setFormState] = useState<FormState>(initialState)
  const [lender, setLender] = useState<any>(null)
  const [successMessage, setSuccessMessage] = useState<any>(null)
  let { id } = useParams();
  let history = useHistory();

  useEffect(() => {
    return () => {
      setFormState(initialState)
      setSuccessMessage(null)
    }
  }, [])

  useEffect(() => {
    console.log('getting lender record', id)
    if (id) {
      const lenderRecord = getLender(id)
        .then((lm) => {
          console.log('got lender model', lm)
          setLender(lm)
        })
    }
  }, [id])

  useEffect(() => {
    if (lender) {
      let updatedState: FormState = {
        name: lender.name,
        email: lender.email
      }
      setFormState(updatedState)
    }
  }, [lender])

  function handleSubmit(event: any,) {
    event.preventDefault()
    if (lender) {
      updateLender()
    } else {
      addLender()
    }
  }
  function onDelete() {
    deleteLender()
      .then(() => {
        history.push("/lender")
      })
  }

  function setInput(key: string, value: any) {
    setFormState({ ...formState, [key]: value })
  }

  async function getLender(id: string) {
    return await DataStore.query(Lender, id)
  }

  async function addLender() {
    try {
      // console.log('in try')
      // if (!formState.name) return
      // console.log('adding lender', formState)
      const lenderObject = { ...formState }
      const result = await DataStore.save(
        new Lender(
          lenderObject
        )
      )
      setLender(result)
      setSuccessMessage(`${result.name} was created.`)
    } catch (err) {
      console.log('error creating Lender:', err)
    }
  }

  async function deleteLender() {
    try {
      if (!lender) {
        throw Error('No lender selected.')
      }
      return await DataStore.delete(lender)
    } catch (err) {
      console.log('error deleting Lender', err)
    }
  }

  async function updateLender() {
    console.log('running update lender', lender)
    try {
      if (!lender || !formState.name) return
      if (lender) {
        const original = await DataStore.query(Lender, lender.id);
        if (original) {
          const updatedLender = await DataStore.save(
            Lender.copyOf(original, (updated: any) => {
              console.log(Object.keys(formState))
              for (const lenderKey of Object.keys(formState)) {
                updated[lenderKey] = formState[lenderKey]
              }
            })
          );
          setLender(updatedLender)
          setSuccessMessage('Updated the Lender.')
        }
      }
    }
    catch (err) {
      console.log('error updating Lender', err)
    }
  }

  return (
    <>
      <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        {successMessage ? <Box mb={2}>
          <Alert variant="outlined" severity="success" children={successMessage} />

          {/* {errors ? errors.map(e => (
            <Box mb={2}><Alert variant="outlined" severity="error" children={e} /></Box>
          )) : null}  */}

        </Box> : null}
        {lender && <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={onDelete}
        >
          Delete
        </Button>}
        <TextField
          // error={state.errors['name']}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="room_name"
          label="Lender Name"
          name="lender_name"
          autoComplete="off"
          autoFocus
          value={formState.name}
          // value={state.name}
          onChange={e => setInput('name', e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="off"
          value={formState.email}
          onChange={e => setInput('email', e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {lender ? 'Edit' : 'Save'}
        </Button>
      </form>
    </>
  )
}