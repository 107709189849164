// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Lender, Inspection, Draft, InspectionValue, WorkDescription, PhaseImages, InspectionInfo } = initSchema(schema);

export {
  Lender,
  Inspection,
  Draft,
  InspectionValue,
  WorkDescription,
  PhaseImages,
  InspectionInfo
};