import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function DraftsTable(props: any) {
  const { drafts, onSelect } = props
  const classes = useStyles();

  function selectHandler(draft: any) {
    if (onSelect) {
      onSelect(draft)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drafts && drafts.map((draft: any) => (
            <TableRow key={draft.id}>
              <TableCell component="th" scope="row">
                {moment(draft._lastChangedAt).format('MM/DD hh:mm a')}
              </TableCell>
              <TableCell align="right">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => selectHandler(draft)}
                >
                  Restore
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}