import React, { useState, useEffect, useMemo, useReducer, useRef } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { API, Storage, Auth } from 'aws-amplify'
import { DataStore, DISCARD } from '@aws-amplify/datastore';
import { ZenObservable } from "zen-observable-ts";
import { Lender, Inspection, Draft } from '../models'
import { useParams, useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InspectionChangeContext } from '../contexts'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import InspectionTable from '../components/VirtualizedGrid'
import Tabs, { a11yProps } from '../components/Tabs'
import DraftsTable from '../components/DraftsTable'
import Modal from '../components/Modal'
import ImageUploader from "react-images-upload";
import { saveAs } from 'file-saver';
import debounce from 'lodash/debounce'
import imageCompression from 'browser-image-compression';
import Exif from 'exif-js';

DataStore.configure({
  errorHandler: (error: any) => {
    console.warn("Unrecoverable error", { error });
  },
  conflictHandler: async (data: any) => {
    // Example conflict handler
    console.log('model conflict', data)
    const modelConstructor = data.modelConstructor;
    if (modelConstructor === Inspection) {
      const remoteModel = data.remoteModel;
      const localModel = data.localModel;
      const newModel = modelConstructor.copyOf(remoteModel, (d) => {
        for (const prop of Object.keys(localModel)) {
          if (prop.startsWith('_')) {
            continue
          }
          d[prop] = localModel[prop]
        }
      });
      return newModel;
    }

    return DISCARD;
  },
  maxRecordsToSync: 30000,
  fullSyncInterval: 60, // minutes
} as any);

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  datePickersWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: 'flex-end'
  },
  imgUploader: {
    '& .uploadPictureContainer': {
      width: '100%'
    }
  },
  imgGrid: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap'
  },
  imgTile: {
    width: '48%',
    height: 700,
    margin: '1%',
    position: 'relative'
  },
  fileInput: {
    display: 'none'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imgRemoveBtn: {
    position: 'absolute',
    bottom: 0,
    display: 'block'
  },
  loaderContainer: {
    textAlign: 'center'
  },
  imageBtnContainer: {
    bottom: 20,
    position: 'sticky',
    textAlign: 'center',
    display: 'block',
    flexGrow: 1,
    left: 0,
    right: 0,
    margin: '24px 0'
  }
}));
const work_descriptions: WorkDescription[] = [{ "name": "Lot Clearing, Footing Poured", "value": 4 }, { "name": "Foundation Walls", "value": 5 }, { "name": "Floor System Complete", "value": 5 }, { "name": "Framing Complete", "value": 8 }, { "name": "Roof Framing, Decking & Felt", "value": 4 }, { "name": "Rough Plumbing & Wiring", "value": 6 }, { "name": "Finished Roof", "value": 2 }, { "name": "Exterior Doors & Windows", "value": 5 }, { "name": "Basement, Fireplace, Misc", "value": 3 }, { "name": "A/C & Heat Rough", "value": 4 }, { "name": "Sheetrock Hung & Taped", "value": 6 }, { "name": "Siding and/or Brick, Stone Complete", "value": 8 }, { "name": "Interior Trim & Doors", "value": 3 }, { "name": "Interior Painting", "value": 4 }, { "name": "Cabinets Installed", "value": 5 }, { "name": "Exterior Trim Complete", "value": 4 }, { "name": "Bathroom Tile", "value": 1 }, { "name": "Electrical & Plumbing Fixtures Complete", "value": 4 }, { "name": "Hardwoods & Carpet Installed", "value": 5 }, { "name": "Stoops, Steps, Walks, & Decks", "value": 3 }, { "name": "Driveway", "value": 2 }, { "name": "Landscaping", "value": 2 }, { "name": "Appliances Installed", "value": 2 }, { "name": "HVAC Complete", "value": 2 }, { "name": "FINAL INSPECTION", "value": 3 }]
interface FormStateManager {
  changes: string[]
  formState: FormState
}

interface LenderData {
  id: string,
  email?: string,
  name?: string
}

interface FormState {
  [key: string]: string | null | string[] | number[] | number | [] | undefined | WorkDescription[] | WorkInspectionValues[] | PhaseImages[] | boolean | InspectionInfo[] | LenderData
  id?: string | null
  name: string
  lenderId: string,
  currentInspectionPhase: number,
  phases: number,
  streetAddress: string
  city: string
  zip: string
  lot?: string
  inspectionValues: WorkInspectionValues[]
  loanId: string,
  workDescriptions: WorkDescription[]
  images?: PhaseImages[]
  borrowerSameAsLender: boolean,
  borrower: string,
  subdivision: string,
  searchField?: string,
  inspectionsInfo: InspectionInfo[],
  lender: LenderData,
  comments: string,
  archived: boolean
}

interface InspectionInfo {
  date?: string
  comments?: string
}

interface PhaseImages {
  phase: number
  keys: string[]
}

interface PhaseImageUrl {
  key: string
  url: string
  file?: File
}

interface PhaseImagesUrlIndex {
  [key: number]: PhaseImageUrl[]
}

interface WorkInspectionValues {
  workDescriptionName: string
  values: Array<number>
}

const defaultPhases = 12

const initialState: FormState = {
  lenderId: '',
  name: '',
  loanId: '',
  streetAddress: '',
  city: '',
  lot: '',
  phases: defaultPhases,
  inspectionValues: generateDefaultInspectionValues(defaultPhases),
  currentInspectionPhase: 1,
  workDescriptions: work_descriptions,
  images: [],
  borrowerSameAsLender: false,
  borrower: '',
  subdivision: '',
  searchField: '',
  zip: '',
  inspectionsInfo: generateDefaultInspectionInfoObjects(defaultPhases),
  lender: {
    id: ''
  },
  comments: '',
  archived: false
}

const formStateKeys = Object.keys(initialState)

interface WorkDescription {
  [key: string]: string | number
  name: string
  value: number
}

type WorkDescriptionTuple = [string, number];
type columnData = (number | string)[][];

const initialFormStateManager = {
  changes: [],
  formState: initialState
}

let columnIndexOffset = 3
let rowIndexOffset = 1

// reducer should take care to log the changed property key under changes: []
// an effect tracks changes to formState and saves them to DataStore
// DataStore field update relies on adding a valid Inspection model key to changes array
function inspectionChangeReducer(state: FormStateManager, action: any) {
  const { type, rowIndex, columnIndex, value, model, key, phase, uploadKey, oldColumnIndex, newColumnIndex }: { type: string, rowIndex: number, columnIndex: number, value: any, model: any, key: string, phase: number, uploadKey: string, oldColumnIndex: number, newColumnIndex: number } = action
  let actualRowIndex
  let newState = {
    changes: [action.type],
    formState: { ...state.formState }
  }
  switch (type) {
    case 'phases':
      // update qty of draws
      if (value < 1) {
        newState.formState.phases = 1
      } else {
        newState.formState.phases = value
      }

      // if current phase is higher than the new qty of phases
      if (newState.formState.currentInspectionPhase > value) {
        newState.formState.currentInspectionPhase = 1
        newState.changes.push('currentInspectionPhase')
      }

      newState.changes.push('inspectionValues')
      // for phases changes must also update inspectionValues arrays
      // every array must match the new phases size
      newState.formState.inspectionValues = newState.formState.inspectionValues.map(({ workDescriptionName, values: inspectionValuesArray }: any, index: number) => {
        if (newState.formState.phases > inspectionValuesArray.length) {
          let columnOffset = newState.formState.phases - inspectionValuesArray.length
          return {
            workDescriptionName,
            values: [...inspectionValuesArray, ...createFilledArray(columnOffset)]
          }
        } else {
          let columnOffset = inspectionValuesArray.length - newState.formState.phases
          let newInspectionValuesArray = [...inspectionValuesArray]
          newInspectionValuesArray.splice(inspectionValuesArray.length - columnOffset, columnOffset)
          return {
            workDescriptionName,
            values: newInspectionValuesArray
          }
        }
      })

      // must update inspectionsInfo
      newState.changes.push('inspectionsInfo')
      if (newState.formState.phases > newState.formState.inspectionsInfo.length) {
        let newCollection = [...newState.formState.inspectionsInfo]
        let difference = newState.formState.phases - newState.formState.inspectionsInfo.length
        for (let i = 0; i < difference; i++) {
          newCollection.push({
            date: undefined,
            comments: ''
          })
        }
        newState.formState.inspectionsInfo = newCollection
      } else if (newState.formState.phases < newState.formState.inspectionsInfo.length) {
        newState.formState.inspectionsInfo = newState.formState.inspectionsInfo.slice(0, newState.formState.phases)
      }
      return newState
    case 'workDescriptionUpdate':
      newState.changes = ['workDescriptions']
      let updatedWorkDescriptionsArray = [...state.formState.workDescriptions]
      actualRowIndex = rowIndex - rowIndexOffset
      let updatedWorkDescriptionObject: WorkDescription = {
        ...updatedWorkDescriptionsArray[actualRowIndex]
      }
      updatedWorkDescriptionObject[key] = value
      updatedWorkDescriptionsArray[actualRowIndex] = updatedWorkDescriptionObject
      newState.formState.workDescriptions = updatedWorkDescriptionsArray
      return newState
    case 'addImages':
      // phase
      // url
      newState.changes = ['images']
      let newImagesArray: PhaseImages[] = []
      if (state.formState.images) {
        let found = false
        for (const phaseImages of state.formState.images) {
          if (phase === phaseImages.phase) {
            found = true
            let keys = [...phaseImages.keys, uploadKey]
            newImagesArray.push({
              phase,
              keys
            })
          } else {
            newImagesArray.push(phaseImages)
          }
        }

        if (!found) {
          newImagesArray.push(
            {
              phase,
              keys: [uploadKey]
            }
          )
        }
      } else {
        newImagesArray = [{
          phase,
          keys: [uploadKey]
        }]
      }
      newState.formState.images = newImagesArray
      return newState
    case 'date':
      newState.changes = ['inspectionsInfo']
      newState.formState.inspectionsInfo = newState.formState.inspectionsInfo.map((val, index) => {
        if (index === newState.formState.currentInspectionPhase - 1) {
          let newVal = { ...val }
          newVal[type] = value
          return newVal
        }
        return val
      })
      return newState
    case 'comments':
      newState.changes = ['comments']
      newState.formState.comments = value
      return newState
    case 'drawComments':
      newState.changes = ['inspectionsInfo']
      newState.formState.inspectionsInfo = newState.formState.inspectionsInfo.map((val, index) => {
        if (index === newState.formState.currentInspectionPhase - 1) {
          let newVal = { ...val }
          newVal['comments'] = value
          return newVal
        }
        return val
      })
      return newState
    case 'lenderId':
      newState.changes = ['lender']
      newState.formState.lender = {
        id: value
      }
      return newState
    case 'removeImage':
      newState.changes = ['images']
      if (uploadKey && phase && state.formState.images) {
        let newImagesArray: PhaseImages[] = []
        for (const phaseImageObject of state.formState.images) {
          if (phase === phaseImageObject.phase) {
            let pIObj = { ...phaseImageObject }

            pIObj.keys = phaseImageObject.keys.filter((k) => {
              if (uploadKey === k) {
                return false
              } else {
                return true
              }
            })
            if (pIObj.keys.length > 0) {
              newImagesArray.push(pIObj)
            }
          } else {
            newImagesArray.push(phaseImageObject)
          }
        }
        newState.formState.images = newImagesArray
      }
      return newState
    case 'archived':
      newState.formState.archived = !!value
      return newState
    case 'inspectionValues':
      let actualColumnIndex = columnIndex - columnIndexOffset
      actualRowIndex = rowIndex - rowIndexOffset
      let updatedArray = [...state.formState.inspectionValues]
      let updateObject = {
        ...updatedArray[actualRowIndex],
        values: [...updatedArray[actualRowIndex].values]
      }
      updateObject.values.splice(actualColumnIndex, 1, value)
      updatedArray[actualRowIndex] = updateObject
      newState.formState.inspectionValues = updatedArray
      return newState
    case 'moveColumn':
      newState.changes = ['inspectionValues']
      // prodive correct inspection value indexed columns
      let moveUpdatedArray = [...state.formState.inspectionValues]
      newState.formState.inspectionValues = moveUpdatedArray.map((workInspectionValuesObj) => {
        let descriptionValues = workInspectionValuesObj.values
        let oldDataCopy = descriptionValues[oldColumnIndex]
        let newDataCopy = descriptionValues[newColumnIndex]
        let arrayCopy = [...descriptionValues]
        // move old data into new position
        arrayCopy.splice(newColumnIndex, 1, oldDataCopy)
        // move new data into old position
        arrayCopy.splice(oldColumnIndex, 1, newDataCopy)
        return {
          ...workInspectionValuesObj,
          values: arrayCopy
        }
      })
      return newState
    case 'hydrate':
      newState.formState = { ...model }
      return newState
    case 'restore':
      newState.changes = formStateKeys
      newState.formState = { ...model }
      return newState
    default:
      if (Object.keys(state.formState).includes(type)) {
        newState.formState[type] = action.value
        return newState
      } else {
        throw new Error('Unrecognized Inspection Change Action:')
      }
  }
}

function createFilledArray(length: number = 0, elValue: number = 0) {
  return Array.from({ length }, (v, i) => elValue)
}

function generateDefaultInspectionValues(phases: number, value: number = 0) {
  let starterInspectionValues = createFilledArray(phases, value)
  let result = work_descriptions.map(wd => ({
    workDescriptionName: wd.name,
    values: [...starterInspectionValues],
  }))
  return result
}

function generateDefaultInspectionInfoObjects(phases: number) {
  let output = []
  for (var i = 0; i < phases; i++) {
    output.push(
      {
        date: undefined,
        comments: ''
      }
    )
  }
  return output
}



export default function CreateInspection() {
  const classes = useStyles()
  const [inspection, setInspection] = useState<Inspection | undefined>()
  const [successMessage, setSuccessMessage] = useState<any>(null)
  let { id } = useParams();
  let history = useHistory();
  const [lenders, setLenders] = useState<any>([])
  const [imageUrls, setImageUrls] = useState<PhaseImagesUrlIndex>([])
  const [imageFiles, setImageFiles] = useState<File[]>([])
  const fileInput = useRef<HTMLInputElement>(null)
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false)
  const saveChanges = useRef(debounce(saveInspectionChanges, 600))
  const emailInputRef = useRef<any>()
  const [sendOption, setSendOption] = useState(0)
  const [destinationEmail, setDestinationEmail] = useState('')
  const [qtyOfDraws, setQtyOfDraws] = useState('')
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [drafts, setDrafts] = useState<Draft[]>([])
  const draftsSubscriptionRef = useRef<ZenObservable.Subscription | undefined>()
  const [loanIdError, setLoanIdError] = useState(false)

  // Note: `dispatch` won't change between re-renders
  const [formStateManager, dispatch] = useReducer(inspectionChangeReducer, initialFormStateManager);
  const memoizedTableValidations = useMemo(
    () => validateTable(
      formStateManager.formState.inspectionValues,
      formStateManager.formState.workDescriptions
    ),
    [
      formStateManager.formState.inspectionValues,
      formStateManager.formState.workDescriptions
    ]
  )
  const memoizedColumnData = useMemo(
    () => createColumnData(
      formStateManager.formState.inspectionValues,
      formStateManager.formState.workDescriptions,
      formStateManager.formState.currentInspectionPhase,
      formStateManager.formState.phases,
      memoizedTableValidations.inspectionTotals
    ),
    [
      formStateManager.formState.inspectionValues,
      formStateManager.formState.workDescriptions,
      formStateManager.formState.currentInspectionPhase,
      formStateManager.formState.phases,
      memoizedTableValidations.inspectionTotals
    ]
  )
  useEffect(() => {
    fetchLenders()
      .then((lendersData) => {
        setLenders(lendersData)
        // if fresh document set lender id on formState
        if (!formStateManager.formState.lender.id) {
          if (lendersData && lendersData.length > 0) {
            dispatch({ type: 'lender', value: { id: lendersData[0].id } })
          }
        }
      })
  }, [])

  // fetch inspection data then fetch urls for images
  useEffect(() => {
    if (id) {
      getInspection(id)
        .then(inspectionData => {
          if (inspectionData) {
            setInspection(inspectionData)
            //hydrate form data
            dispatch({ type: 'hydrate', model: { ...inspectionData } })

            // if we have inspection images for current phase get the urls
            if (inspectionData.images) {
              for (const inspectionImagesObject of inspectionData.images) {
                getImageUrls(inspectionImagesObject.phase, ...inspectionImagesObject.keys.map((key) => {
                  let keyFileTuple: [string, File?] = [key]
                  return keyFileTuple
                }))
              }
            }
            if (inspectionData.lender) {
              // set lender email
              setDestinationEmail(inspectionData.lender.email)
            }
          }
        })
    }
  }, [id])

  // fetch drafts
  useEffect(() => {
    async function getDrafts() {
      const draftsData = (await DataStore.query(Draft)).filter(c => c.inspectionId === id);
      setDrafts(draftsData)
    }
    getDrafts()
  }, [id])

  // setup drafts subscription
  useEffect(() => {
    const subscription = DataStore.observe(Draft).subscribe(msg => {
      if (msg.opType === 'INSERT') {
        setDrafts(prevDrafts => [...prevDrafts, msg.element])
      }
    });
    draftsSubscriptionRef!.current = subscription
    return () => {
      draftsSubscriptionRef!.current!.unsubscribe()
    }
  }, [id])

  // update or create inspection on formStateManager changes
  useEffect(() => {
    if (formStateManager.formState.lender.id) {
      if (!id) {
        createInspection(formStateManager.formState)
          .then(inspectionModel => {
            setInspection(inspectionModel)
            history.push(`/inspection/${inspectionModel?.id}`)
          })
      } else if (id && inspection) {
        saveChanges.current(inspection, formStateManager.changes, formStateManager.formState)
      }
    }
  }, [formStateManager])

  // keep the qtyOfDraws in sync with Inspection model API data
  useEffect(() => {
    setQtyOfDraws(`${formStateManager.formState.phases}`)
  }, [formStateManager.formState.phases])

  const handleSendOptionChange = (newValue: number) => {
    setSendOption(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  async function getPdf(inspectionId: string) {
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      response: true
    };
    let apiPath = `/pdf/${inspectionId}`
    if (sendOption == 1) {
      let email = emailInputRef.current.value
      apiPath += `/?email=${encodeURIComponent(email)}`
    }
    setLoadingPdf(true)
    let prom = API.get('api6a1a43a9', apiPath, myInit)
    prom.then((res) => {
      saveAs(res.data.url, res.data.key);
    })
    return prom
  }

  async function getInspection(id: string) {
    try {
      const inspectionData = await DataStore.query(Inspection, id);
      return inspectionData
    } catch (err) { console.log('error fetching Inspection', err) }
  }

  async function deleteInspection() {
    try {
      if (!inspection) {
        throw Error('No inspection selected.')
      }
      return await DataStore.delete(inspection)
    } catch (err) {
      console.log('error deleting Inspection', err)
    }
  }

  async function getImageUrls(phase: number, ...keyFileTuples: [string, File?][]) {
    for (const [key, file] of keyFileTuples) {
      Storage.get(key)
        .then((urlInfo) => {
          updateImageUrls(phase, [key, urlInfo as string, file])
        })
    }
  }

  async function fetchLenders() {
    try {
      const lendersData = await DataStore.query(Lender);
      return lendersData
    } catch (err) { console.log('error fetching Lenders', err) }
  }

  function onDelete() {
    deleteInspection()
      .then(() => {
        history.push("/inspection")
      })
  }

  function onArchive(archive: boolean = true) {
    dispatch({ type: 'archived', value: archive })
  }

  async function saveInspectionChanges(inspection: Inspection, keys: Array<keyof Omit<Inspection, "id">>, inspectionState: FormState) {
    // const inspectionData = await DataStore.query(Inspection, id);
    let clone = Inspection.copyOf(inspection, (updated: any) => {
      // delete updated.searchField
      for (const key of keys) {
        if (formStateKeys.includes(key)) {
          updated[key] = inspectionState[key]
        }
      }
    })

    DataStore.save(
      clone
    )
      .then((updatedInspectionModel) => {
        setInspection(updatedInspectionModel)
      })
  }

  function setInput(key: keyof FormState | keyof WorkInspectionValues | 'drawComments', value: any) {
    dispatch({ type: key, value })
  }


  async function createInspection(state: FormState) {
    try {
      const inspectionObject: any = { ...state }
      const result = await DataStore.save(
        new Inspection(
          inspectionObject
        )
      )
      return result
    } catch (err) {
      console.log('error creating Inspection:', err)
    }
  }

  function createColumnData(inspectionValues: WorkInspectionValues[], workDescriptions: WorkDescription[], currentInspectionPhase: number, phases: number, inspectionTotals: number[]) {
    let headers = [
      '',
      "Work Description",
      "%",
      ...Array.from({ length: phases }, (v, i) => i + 1),
    ];
    let completedToDate = []
    let completedTotal = 0
    // calculate completedToDate
    for (const total of inspectionTotals) {
      completedTotal += total
      completedToDate.push(completedTotal)
    }
    // all of the rows with their respective columns (inspection values)
    const list: Array<Array<number | string>> = [
      headers,
      ...workDescriptions.map((v, i) => {

        return [i + 1, ...Object.values(v), ...inspectionValues[i].values];
      }),
      ['', 'Completed This Inspection', '100', ...inspectionTotals],
      ['', 'Completed To Date', '', ...completedToDate],
    ];
    return list;
  }
  
  const compressImage = async (imageFile) => {

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    }
    try {
      const orientation = await imageCompression.getExifOrientation(imageFile);
      // If the image is in portrait mode, no need to rotate it
      if (orientation === 1 || orientation === -1) {
        const compressedFile = await imageCompression(imageFile, options);
        return compressedFile;
      }
      // If the image is in landscape mode, rotate it by 90 degrees
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      const rotatedFile = await new Promise((resolve, reject) => {
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.height;
          canvas.height = img.width;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(Math.PI / 180);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);
          canvas.toBlob(async (blob) => {
            const rotatedFile = await imageCompression(new File([blob], imageFile.name, { type: imageFile.type }),options);
            resolve(rotatedFile);
          }, imageFile.type);
        };
      });
      return rotatedFile; 
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  async function uploadFiles(phase: number, files: File[]) {
    // resize before upload
    for (const file of files) {
      let fileKey = `${Date.now()}${file.name}`;
      //let compressed = await compressImageFile(file)
      let compressed = await compressImage(file);
      Storage.put(fileKey, compressed, {
        contentType: file.type,
        expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000)
        // attempting to upload with acl public gives 403 denied error
        // acl: 'public-read'
      })
        .then((uploadResult: any) => {
          getImageUrls(phase, [uploadResult.key, file])
          dispatch({ type: 'addImages', uploadKey: uploadResult.key as string, phase })
        })
        .catch(err => console.log(err));
    }
  }

  function updateImageUrls(phase: number, ...keyUrlFileTuples: [string, string, File?][]) {
    setImageUrls(
      (state) => {
        let newUrls = keyUrlFileTuples.map(([key, url, file]) => {
          return {
            key,
            url,
            file
          }
        })
        let copy = { ...state }
        if (copy[phase]) {
          copy[phase] = [...copy[phase], ...newUrls]
        } else {
          copy[phase] = newUrls
        }
        return copy
      })
  }

  function deleteImage(phase: number, s3ObjectKey: string) {
    // remove image from inspection images
    dispatch({ type: 'removeImage', uploadKey: s3ObjectKey, phase })
    // remove imageUrl
    setImageUrls((state) => {
      let newImageUrls = { ...state }
      newImageUrls[phase] = newImageUrls[phase].filter((imageUrlObj) => {
        if (imageUrlObj.key === s3ObjectKey) {
          return false
        } else {
          return true
        }
      })
      return newImageUrls
    })

    Storage.remove(s3ObjectKey)
  }

  async function checkDuplicateLoanId(loanId: string) {
    let isDuplicate = false

    if (!loanId) {
      return false
    }

    const matchingInspections = ((await DataStore.query(
      Inspection,
      (i: any) => i.loanId("eq", loanId)
    )) as any)
    if (matchingInspections.length > 0) {
      let first = matchingInspections[0]
      if (first.id !== formStateManager.formState.id) {
        isDuplicate = true
      }
    }
    return isDuplicate
  }

  function handleDownloadPDF() {
    if (loadingPdf) {
      return
    }
    if (formStateManager.formState.id) {
      getPdf(formStateManager.formState.id)
        .then(() => {
          setLoadingPdf(false)
        })
    }
  }

  function validateTable(inspectionValues: WorkInspectionValues[], workDescriptions: WorkDescription[]) {
    let inspectionTotals: any = []
    let rowTotals: any = inspectionValues.map((row, rowIndex) => {
      let rowTotal = 0
      let lastValueIndex
      let error = false
      let completed = false
      for (const [index, inspectionColumnVal] of row.values.entries()) {
        if (!inspectionTotals[index] && inspectionTotals[index] !== 0) {
          inspectionTotals[index] = 0
        }
        inspectionTotals[index] += inspectionColumnVal
        rowTotal += inspectionColumnVal
        if (inspectionColumnVal) {
          lastValueIndex = index
        }
      }

      // error check
      if (workDescriptions[rowIndex].value < rowTotal) {
        error = true
      } else if (workDescriptions[rowIndex].value === rowTotal) {
        completed = true
      }
      return {
        total: rowTotal,
        error,
        lastValueIndex,
        completed
      }
    }, [])
    return {
      inspectionTotals,
      rowTotals
    }
    // return inspectionValues.reduce((accumulator, inspectionValue) => accumulator + inspectionValue.values.reduce((inspectionTotal, inspectionFieldValue) => inspectionTotal + inspectionFieldValue), 0)
  }

  function toggleDeleteModal() {
    setDeleteModalVisible((s) => !s)
  }

  function deleteModalConfirm() {
    onDelete()
  }

  function restoreDraft(draft: Draft) {
    let inspectionData = JSON.parse(draft.data)
    dispatch({ type: 'restore', model: inspectionData })
  }

  function inspectionDraw(inspection: number, inspectionValues: WorkInspectionValues[]) {
    let inspectionIndex = inspection - 1

    return inspectionValues.reduce((accumulator, inspectionWorkObject) => accumulator + inspectionWorkObject.values[inspectionIndex], 0)
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  let tabs = []
  let tabPanels = []

  for (let i = 0; i < formStateManager.formState.phases; i++) {
    tabs.push(<Tab label={`Draw ${i + 1}`} {...a11yProps(i)} key={i} />)
    // check if we have images for this phase
    // keyed by phase
    let images: any = []
    if (imageUrls[i + 1]) {
      images = imageUrls[i + 1].map((imgUrlObj, index) => {
        return (
          <div className={classes.imgTile} key={index}>
            <img className={classes.img} src={imgUrlObj.url} />
            <Button
              className={classes.imgRemoveBtn}
              type="button"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                let phase = i + 1
                deleteImage(phase, imgUrlObj.key)
              }}
            >Delete</Button>
          </div>
        )
      })
    }
    tabPanels.push(<div className={classes.imgGrid}>{images}</div>)
  }

  let pdfTabButtons = [
    <Tab key={0} label="Download" {...a11yProps(0)} />,
    <Tab key={1} label="Send Email" {...a11yProps(1)} />
  ]
  let pdfTabs = [
    <TabPanel
      value={sendOption}
      index={0}
    >
      {loadingPdf ?
        <div className={classes.loaderContainer}>
          <CircularProgress />
          <Typography variant="body1">Generating PDF...</Typography>
        </div>
        :
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleDownloadPDF}
        >
          Download PDF
      </Button>
      }
    </TabPanel>,
    <TabPanel
      value={sendOption}
      index={1}
    >
      {loadingPdf ?
        <div className={classes.loaderContainer}>
          <CircularProgress />
          <Typography variant="body1">Generating PDF...</Typography>
        </div>
        : <>
          <TextField
            type="email"
            id="lender-email"
            label="Email"
            margin="normal"
            variant="outlined"
            name="lender-email"
            autoComplete="off"
            defaultValue={destinationEmail}
            inputRef={emailInputRef}
          />
          <br />
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
          >
            Send Email
      </Button>
        </>}
    </TabPanel>
  ]

  return (
    <>
      <form className={classes.form}>
        {successMessage ? <Box mb={2}>
          <Alert variant="outlined" severity="success" children={successMessage} />

          {/* {errors ? errors.map(e => (
            <Box mb={2}><Alert variant="outlined" severity="error" children={e} /></Box>
          )) : null}  */}

        </Box> : null}
        {/* {lender && <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={onDelete}
        >
          Delete
        </Button>} */}

        <TextField
          // error={state.errors['name']}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="loanId"
          name="loanId"
          autoComplete="off"
          value={formStateManager.formState.loanId}
          onChange={e => setInput('loanId', e.target.value)}
          onBlur={(e: any) => {
            checkDuplicateLoanId(e.target.value)
              .then((isDuplicate) => {
                if (isDuplicate) {
                  // clear loan id because attempting to use duplicate
                  setInput('loanId', '')
                  setLoanIdError(true)
                } else {
                  setLoanIdError(false)
                }
              })
          }}
          error={loanIdError}
          label={loanIdError ? 'You tried entering a duplicate Loan ID. Please choose a unique Loan ID.' : 'Loan #'}
        />

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel id="demo-simple-select-outlined-label">Lender</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            // value={age}
            onChange={e => {
              setInput('lenderId', e.target.value)
              for (const lender of lenders) {
                if (lender.id == e.target.value) {
                  setDestinationEmail(lender.email)
                  break;
                }
              }
            }}
            label="Lender"
            required
            value={formStateManager.formState.lender.id}
          >
            {lenders.map((lender: any) => {
              return (
                <MenuItem key={lender.id} value={lender.id}>{lender.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <TextField
          // error={state.errors['name']}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="borrower"
          label="Borrower"
          name="borrower"
          autoComplete="off"
          autoFocus
          value={formStateManager.formState.name}
          onChange={e => setInput('name', e.target.value)}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formStateManager.formState.borrowerSameAsLender}
              onChange={(e) => {
                setInput('borrowerSameAsLender', e.target.checked)
              }}
              name="borrowerSameAsLender"
              color="primary"
            />
          }
          label="Borrower same as Builder?"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="lot"
          label="Lot"
          name="lot"
          autoComplete="off"
          value={formStateManager.formState.lot}
          onChange={e => setInput('lot', e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="streetAddress"
          label="Construction Address"
          name="streetAddress"
          autoComplete="off"
          value={formStateManager.formState.streetAddress}
          onChange={e => setInput('streetAddress', e.target.value)}
        />

        <TextField
          // error={state.errors['name']}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="city"
          label="City"
          name="city"
          autoComplete="off"
          value={formStateManager.formState.city}
          onChange={e => setInput('city', e.target.value)}
        />

        <TextField
          // error={state.errors['name']}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="zip"
          label="Zip"
          name="zip"
          autoComplete="off"
          value={formStateManager.formState.zip}
          onChange={e => setInput('zip', e.target.value)}
        />

        <TextField
          id="subdivision"
          label="Subdivision"
          margin="normal"
          variant="outlined"
          name="subdivision"
          autoComplete="off"
          value={formStateManager.formState.subdivision}
          onChange={(e) => {
            setInput('subdivision', e.target.value);
          }}
        />

        <TextField
          id="outlined-helperText"
          label="Quantity of Draws"
          margin="normal"
          variant="outlined"
          value={qtyOfDraws}
          type="number"
          onChange={(e: any) => {
            if (e.target.value < 100) {
              setQtyOfDraws(e.target.value);
            }
          }}
          onBlur={(e: any) => {
            if (e.target.value < 100) {
              setInput('phases', parseInt(e.target.value));
            }
          }}
        />

        <FormControl variant="outlined" margin="normal">
          <InputLabel id="demo-simple-select-outlined-label">
            Current Draw
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={formStateManager.formState.currentInspectionPhase}
            onChange={e => setInput('currentInspectionPhase', e.target.value)}
            label="Current Draw"
          >
            {Array.from({ length: formStateManager.formState.phases }, (v, i) => {
              return <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
            })}
          </Select>
        </FormControl>

        <TextField
          id="generalComments"
          label="General Comments"
          margin="normal"
          variant="outlined"
          name="comments"
          autoComplete="off"
          multiline
          rows={4}
          value={formStateManager.formState.comments}
          onChange={(e) => {
            setInput('comments', e.target.value);
          }}
        />
        <TextField
          id="drawComments"
          label="Draw Comments"
          margin="normal"
          variant="outlined"
          name="drawComments"
          autoComplete="off"
          multiline
          rows={4}
          value={formStateManager.formState.inspectionsInfo[formStateManager.formState.currentInspectionPhase - 1].comments}
          onChange={(e) => {
            setInput('drawComments', e.target.value);
          }}
        />
        <TextField
          id="date"
          label="Date"
          type="date"
          value={formStateManager.formState.inspectionsInfo[formStateManager.formState.currentInspectionPhase - 1].date || ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setInput('date', e.target.value);
          }}
        />

        <InspectionChangeContext.Provider value={dispatch} >
          <InspectionTable phases={formStateManager.formState.phases} currentInspectionPhase={formStateManager.formState.currentInspectionPhase} columnData={memoizedColumnData} tableValidationData={memoizedTableValidations} />
        </InspectionChangeContext.Provider>

        <Tabs tabs={tabs} panels={tabPanels} value={formStateManager.formState.currentInspectionPhase - 1} onChange={(val: any) => {
          dispatch({ type: 'currentInspectionPhase', value: val + 1 })
        }} />

        <div className={classes.imageBtnContainer}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              fileInput.current?.click()
            }}
          >
            <CameraAltIcon />&nbsp;
          Choose Images
        </Button>
        </div>

        <Tabs
          value={sendOption}
          onChange={handleSendOptionChange}
          aria-label="Choose send option"
          tabs={pdfTabButtons}
          panels={pdfTabs}
        />

        <input
          type="file"
          className={classes.fileInput}
          ref={fileInput}
          name="images"
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            // newly added files
            let fileList: FileList = fileInput!.current!.files!
            let files: File[] = []
            for (var i = 0; i < fileList.length; i++) {
              files.push(fileList.item(i) as File)
            }
            uploadFiles(formStateManager.formState.currentInspectionPhase, files)
          }}
          accept="image/*"
        />
        <Box my={6}>
          <Typography variant="h4">Archive</Typography>
        </Box>

        {!!formStateManager.formState.id && formStateManager.formState.archived ? (
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => onArchive(false)}
          >
            Un-Archive
          </Button>
        ) : (
            <Button
              fullWidth={false}
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => onArchive(true)}
            >
              Archive
            </Button>
          )}

        <Box my={6}>
          <Typography variant="h4">Delete Permanently</Typography>
        </Box>

        <Button
          fullWidth={false}
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={() => setDeleteModalVisible(true)}
        >
          Delete
        </Button>

        <DraftsTable drafts={drafts} onSelect={restoreDraft} />

      </form>
      <Modal visible={deleteModalVisible} onRequestClose={toggleDeleteModal} onConfirm={deleteModalConfirm}>
        <div>Delete this Inspection?</div>
      </Modal>
    </>
  )
}