import React, { useEffect, useState } from 'react'
import DataTable from "../components/DataTable";
import Link from '@material-ui/core/Link';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { ListLendersQuery } from '../API'
import { listLenders } from '../graphql/queries'
import { Lender } from '../models'
import { DataStore, Predicates } from '@aws-amplify/datastore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

Amplify.configure(awsconfig);

let columns: any[] = [
  {
    key: 'id',
    header: 'Id',
    renderFn: (rowData: any) => {
      return (
        <Link component={RouterLink} to={`/lender/${rowData.id}`}>{rowData.id}</Link>
      )
    }
  },
  {
    key: 'name',
    header: 'Name',
    renderFn: (rowData: any) => {
      return (
        <Link component={RouterLink} to={`/lender/${rowData.id}`}>{rowData.name}</Link>
      )
    }
  }
]


export default function LenderPage() {
  const [lenders, setLenders] = useState<any>([])
  useEffect(() => {
    fetchLenders()
  }, [])
  async function fetchLenders() {
    try {
      const lendersData = await DataStore.query(Lender);
      setLenders(lendersData)
    } catch (err) { console.log('error fetching todos') }
  }
  return (
    <>
      <DataTable columns={columns} data={lenders} />
      <Box my={3}>
        <RouterLink to="/lender/add">
          <Button color="primary" variant="contained">Add Lender</Button>
        </RouterLink>
      </Box>

    </>
  )
}