import React, { useContext, useState, useReducer } from "react";
import { AutoSizer, Grid, MultiGrid } from "react-virtualized";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { InspectionChangeContext } from "../contexts";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import clsx from "clsx";
import { motion } from "framer-motion";
import zIndex from "@material-ui/core/styles/zIndex";

interface VirtualizedGridProps {
  phases?: number;
  currentInspectionPhase?: number;
  columnData?: columnData;
  tableValidationData?: any;
  qtyOfDraws?: any;
}

type WorkDescriptionTuple = [string, number];

const columnOffset = 3;

const useStyles = makeStyles({
  table: {
    width: "auto",
    margin: "20px auto 0 auto",
  },
  input: {
    padding: "0 6px",
    height: "100%",
    textAlign: "center",
  },
  inputRoot: {
    height: "100%",
    fontSize: "0.875rem",
  },
  selectRoot: {
    minWidth: 226,
  },
  textFieldRoot: {
    width: "100%",
    height: "100%",
  },
  textFieldWhite:{
    color: "white",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridRoot: {
    border: "1px solid rgba(0, 0, 0, 0.42)",
    margin: "20px auto 0 auto",
  },
  borderLeft: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.42)",
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  startCap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  red: {
    backgroundColor: red[400],
  },
  greyBg: {
    backgroundColor: "#e0e0e0",
  },
  yellow: {
    backgroundColor: yellow[400],
  },
  green: {
    backgroundColor: green[400],
  },
  blue: {
    backgroundColor: "#64b5f6",
  },
  draggableCellContainer: {
    background: "#fff",
    zIndex: 1,
  },
  draggableInnerCell: {
    height: "100%",
    width: "100%",
  },
  draggingInnerCell: {
    backgroundColor: "#6e788099",
  },
  dragBorderCell: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "solid 2px red",
  },
  dragging: {
    zIndex: 10,
  },
});

type columnData = (number | string)[][];

interface MemoizedTextFieldProps {
  value: number | string;
  columnIndex: number;
  rowIndex: number;
}

const MemoizedTextField = React.memo(function MemoizedTextField({
  value,
  columnIndex,
  rowIndex,
  currentInspectionPhase
}: any) {
  const dispatch = useContext(InspectionChangeContext);
  const classes = useStyles();

  let inputType = "number";
  if (columnIndex < 2) {
    inputType = "text";
  }

  let disabled = false;
  if (columnIndex == 1 || currentInspectionPhase !== columnIndex-2) {
    disabled = true;
  }

  function handleChange(e: any) {
    let val = e.target.value;
    if (inputType == "number") {
      if (val) {
        // val = 0
        val = parseInt(val);
      }
    }
    let reducerAction = {
      type: "inspectionValues",
      value: val,
      columnIndex,
      rowIndex,
      key: "",
    };

    // is this a work description column / work description value change?
    if (columnIndex > 0 && columnIndex < 3) {
      let key = "";
      if (columnIndex == 1) {
        key = "name";
      } else {
        key = "value";
      }
      reducerAction.type = "workDescriptionUpdate";
      reducerAction.key = key;
    }

    dispatch && dispatch(reducerAction);
  }
  return (
    <TextField
      type={inputType}
      classes={{
        root: clsx(
          classes.textFieldRoot,
          columnIndex > 0 && classes.borderLeft
        ),
      }}
      inputProps={{
        step: 1,
        min: 0,
      }}
      onBlur={(e) => {
        if (!e.target.value) {
          let reducerAction = {
            type: "inspectionValues",
            value: 0,
            columnIndex,
            rowIndex,
            key: "",
          };
          dispatch && dispatch(reducerAction);
        }
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: clsx(classes.input,{[classes.textFieldWhite]: columnIndex > 0 && columnIndex == 2 && classes.green}),
          //input: classes.input,
        },
      }}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onFocus={(e) => {
        // auto select input value for editing
        let event: any = e as any;
        event.target.select();
        // workaround for selecting on ios mobile
        // https://stackoverflow.com/a/55803519/2246194
        event.target.type = "text";
        // .setSelectionRange() method only available on text inputs
        event.target.setSelectionRange(0, e.target.value.length);
        event.target.type = "number";
      }}
    />
  );
});

function TableHeaderCellRenderer(props: any) {
  const classes = useStyles();
  const [currentlyDragging, setCurrentlyDragging] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(70);
  const inspectionDispatch = useContext(InspectionChangeContext);

  const { dragOffset, dispatchDragOffset } = useContext(HeaderCellDragContext);

  const { columnIndex, key, rowIndex, style, columnData, red } = props;

  const titleCellOffset = 3 - 1;

  const columns = columnData[0].length;

  function calcDragOffset(offset: number) {
    let trueIndex = columnIndex - titleCellOffset;
    let targetStart = trueIndex * width;
    let totalOffset = offset + targetStart;

    return totalOffset;
  }

  function determineDragColumnIndex(offset: number) {
    if (!offset) {
      return null;
    }
    let columnDividend = offset / width;
    let finalColumnIndex = Math.floor(columnDividend);

    let totalInspectionColumns = columns - (titleCellOffset + 1);

    if (finalColumnIndex < 0) {
      finalColumnIndex = 0;
    } else if (finalColumnIndex >= totalInspectionColumns) {
      finalColumnIndex = totalInspectionColumns - 1;
    } else {
      finalColumnIndex -= 1;
    }
    return finalColumnIndex;
  }

  function onDrag(event, info) {
    let calculatedDragOffset = calcDragOffset(info.offset.x);

    // update drag offset context
    dispatchDragOffset({ type: "setDragOffset", offset: calculatedDragOffset });
  }

  function onDragStart() {
    setCurrentlyDragging(true);
  }

  function onDragEnd(event, info) {
    setCurrentlyDragging(false);
    dispatchDragOffset({ type: "default" });

    let calculatedDragOffset = calcDragOffset(info.offset.x);
    let newIndex = determineDragColumnIndex(calculatedDragOffset);
    let actualMoveIndex = newIndex;
    let totalInspectionColumns = columns - (titleCellOffset + 1);

    // inform inspection reducer of column move
    inspectionDispatch({
      type: "moveColumn",
      oldColumnIndex: columnIndex - (titleCellOffset + 1),
      newColumnIndex: actualMoveIndex,
    });
  }

  return columnIndex > titleCellOffset ? (
    <div
      style={style}
      className={clsx(classes.draggableCellContainer, {
        [classes.dragging]: currentlyDragging,
      })}
    >
      <motion.div
        onDrag={onDrag}
        drag="x"
        dragConstraints={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        dragElastic={1}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        className={clsx(
          classes.draggableInnerCell,
          classes.borderBottom,
          classes.center,
          classes.blue,
          {
            [classes.borderLeft]: columnIndex > 0,
            [classes.draggingInnerCell]: currentlyDragging,
          }
        )}
      >
        {currentlyDragging
          ? determineDragColumnIndex(dragOffset) + 1
          : columnData[rowIndex][columnIndex]}
      </motion.div>
      {dragOffset >= 0 &&
      determineDragColumnIndex(dragOffset) ==
        columnIndex - (titleCellOffset + 1) ? (
        <div className={clsx(classes.dragBorderCell)}></div>
      ) : null}
    </div>
  ) : (
    <div
      style={style}
      className={clsx(classes.borderBottom, classes.center, classes.blue, {
        [classes.borderLeft]: columnIndex > 0,
      })}
    >
      {columnData[rowIndex][columnIndex]}
    </div>
  );
}

// context value stores null or current drag offset of a table cell
const HeaderCellDragContext = React.createContext(null);

function dragStateReducer(state, action) {
  switch (action.type) {
    case "setDragOffset":
      return action.offset;
    default:
      return null;
  }
}

export default React.memo(function VirtualizedGrid({
  phases = 12,
  currentInspectionPhase = 1,
  columnData = [],
  tableValidationData,
}: VirtualizedGridProps) {
  const classes = useStyles();
  console.log(currentInspectionPhase);
  const [dragOffset, dispatchDragOffset] = useReducer(dragStateReducer, null);

  // const [columnData, setColumnData] = useState<columnData>([]);
  // useEffect(() => {
  //   let headers = [
  //     "Work Description",
  //     "%",
  //     ...Array.from({ length: phases }, (v, i) => i + 1),
  //   ];
  //   const list: Array<Array<number | string>> = [
  //     headers,
  //     ...Array.from(work_descriptions, (v, i) => {
  //       return [...v, ...Array.from({ length: phases }, (v, i) => 0)];
  //     }),
  //   ];
  //   setColumnData(list);
  // }, [phases, setColumnData]);

  // function handleColumnChange(e: any, rowIndex: number, columnIndex: number) {
  //   setColumnData(
  //     columnData.map((x, i) => {
  //       if (i == rowIndex) {
  //         return x.map((y, j) => (j == columnIndex ? e.target.value : y));
  //       } else {
  //         return x;
  //       }
  //     })
  //   );
  // }

  interface Lookup {
    [key: number]: boolean;
  }

  interface ValidationData {
    complete: Lookup;
    error: Lookup;
  }

  let validationData: ValidationData = {
    // index completed and error rows
    complete: {} as Lookup,
    error: {} as Lookup,
  };

  function validateColumnData() {
    // validate each row
    let complete: Lookup = {};
    let error: Lookup = {};
    columnData.map((columns, index) => {
      // skip header row
      if (index < 1) {
        return;
      }
      let actualColumns = [...columns];

      // remove the default info columns
      let defaultColumns = actualColumns.splice(0, 3);
      let valueColumns: number[] = actualColumns as number[];
      let workValue = defaultColumns.pop() as number;
      let totalValue: number = valueColumns.reduce(
        (accumulator: string | number, currentValue: string | number) => {
          return (
            parseInt(accumulator as string) + parseInt(currentValue as string)
          );
        }
      );

      // completed
      if (totalValue == workValue) {
        complete[index] = true;
      } else if (totalValue > workValue) {
        // this row is in error
        error[index] = true;
      }
    });
    return {
      complete,
      error,
    };
  }

  validationData = validateColumnData();

  function cellRenderer({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    key: any;
    style: any;
  }) {
    let yellow = false;
    let red = false;
    let grey = false;
    let blue = false;

    if (rowIndex == 0) {
      blue = true;
    }

    if (rowIndex > 0 && tableValidationData) {
      let actualRowIndex = rowIndex - 1;
      let actualColumnIndex = columnIndex - columnOffset;

      if (tableValidationData.rowTotals[actualRowIndex]) {
        if (tableValidationData.rowTotals[actualRowIndex].error) {
          red = true;
        } else if (tableValidationData.rowTotals[actualRowIndex].completed) {
          if (
            actualColumnIndex >
            tableValidationData.rowTotals[actualRowIndex].lastValueIndex
          ) {
            yellow = true;
          }
        } else if (rowIndex % 2 < 1) {
          grey = true;
        }
      }
    }

    if (rowIndex == columnData.length - 1) {
      blue = true;
    }

    if (rowIndex == columnData.length - 2) {
      blue = true;
    }

    return rowIndex < 1 ? (
      <TableHeaderCellRenderer
        columnIndex={columnIndex}
        key={key}
        rowIndex={rowIndex}
        style={style}
        columnData={columnData}
      />
    ) : (
      <div
        key={key}
        style={style}
        className={`
  ${columnIndex > 2 && yellow ? classes.yellow : ""}
  ${grey ? classes.greyBg : ""}
  ${columnIndex > 2 && red ? classes.red : ""}
  ${columnIndex < 2 && classes.startCap}
  ${columnIndex == 1 ? classes.borderLeft : ""}
  ${rowIndex == 0 && classes.borderBottom}
  ${rowIndex == 0 && columnIndex > 0 && classes.borderLeft}
  ${(rowIndex == 0 || rowIndex == columnData.length - 1) && classes.center}
  ${rowIndex == columnData.length - 1 ? classes.borderLeft : ""}
  ${rowIndex > 0 && columnIndex == 2 && classes.green}
  ${blue ? classes.blue : ""}
  `}
      >
        {rowIndex > 0 &&
        columnIndex > 1 &&
        rowIndex !== columnData.length - 1 ? (
          // <span>test</span>
          // <input type="text" />
          <MemoizedTextField
            value={columnData[rowIndex][columnIndex]}
            rowIndex={rowIndex}
            currentInspectionPhase={currentInspectionPhase}
            columnIndex={columnIndex}
          />
        ) : (
          // <Input defaultValue="Hello world" inputProps={{ 'aria-label': 'description' }} />
          // <TextField
          //   variant="outlined"
          //   // classes={{ root: classes.textFieldRoot }}
          //   // InputProps={{
          //   //   classes: { input: classes.input },
          //   // }}
          //   defaultValue={columnData[rowIndex][columnIndex]}
          //   onChange={(e) => { }}
          // />
          columnData[rowIndex][columnIndex]
        )}
        {/* <div key={key} style={style}>
          {list[rowIndex][columnIndex]}
        </div> */}
      </div>
    );
  }
  return (
    <AutoSizer style={{ height: "auto", width: "auto" }}>
      {({ height, width }) => {
        return (
          <HeaderCellDragContext.Provider
            value={{ dragOffset, dispatchDragOffset }}
          >
            <MultiGrid
              className={`${classes.gridRoot} `}
              cellRenderer={cellRenderer}
              columnCount={columnData[0] ? columnData[0].length : 0}
              fixedColumnCount={3}
              columnWidth={({ index }) => {
                switch (index) {
                  case 1:
                    return 300;
                  default:
                    return 70;
                }
              }}
              rowCount={columnData.length}
              height={60 * columnData.length}
              rowHeight={60}
              width={width}
            />
          </HeaderCellDragContext.Provider>
        );
      }}
    </AutoSizer>
  );
});
